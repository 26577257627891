<template>
  <div>
    <div v-if="RKMain">
      <!-- 左侧搜索 -->
      <div class="display_flex top">
        <div class="search-top">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item>
              <el-date-picker @change="timeChange" v-model="formInline.time" type="daterange" range-separator="至"
                start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD" />
            </el-form-item>
            <el-form-item label="批号">
              <el-input v-model="formInline.batch_no" @change="formSearchBatchNoChange" clearable />
            </el-form-item>
            <el-form-item>
              <el-input class="w-208" @input="search" v-model="formInline.drug_name" @keydown.enter="search" clearable
                placeholder="名称/拼音">
                <template #suffix>
                  <div @click="search" class="searchcss">
                    <i class="fa fa-search"></i>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <!-- <el-form-item>
              <el-input class="w-208" v-model="formInline.status" clearable placeholder="出库患者名称/拼音">
                <template #suffix>
                  <div @click="search" class="searchcss">
                    <i class="fa fa-search"></i>
                  </div>
                </template>
              </el-input>
            </el-form-item> -->
            <el-button class="color-vice" type="primary" @click="exportTable">
              <i class="fa fa-arrow-down"></i>导出
            </el-button>
            <el-button class="color-grey" @click="print()">
              <i class="fa fa-print"></i>
              打印
            </el-button>
          </el-form>
        </div>
        <div class="item">
          <!--
          <el-button class="color-yellow" type="primary" @click="RKClick">
            <i class="fa fa-arrow-up"></i>出库
          </el-button>
          <el-button class="color-green" type="primary" @click="CKTJClick">
            <i class="fa fa-file-text-o"></i>统计
          </el-button>
          -->
         
        </div>
      </div>
      <!-- 右侧按钮 -->


      <!-- 表格内容 -->
      <div>
        <el-table class="sss" v-loading="loading" ref="multipleTableRef" :data="tableData" size="small" style="width: 100%" stripe
          border  max-height="44vh"  @selection-change="handleSelectionChange">
          <!-- <el-table-column type="selection" width="55" /> -->
          <el-table-column width="180" prop="name" label="名称" fixed show-overflow-tooltip>
            <template #default="scope">
              <span>{{ scope.row.drug.name }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150" property="drug.medi_spec" fixed label="规格" show-overflow-tooltip />
          <el-table-column width="150" prop="order_no" label="入库单号" />
          <el-table-column width="120" prop="batch_no" label="批号" show-overflow-tooltip />
          <el-table-column width="180" property="drug.company" label="厂家" show-overflow-tooltip />
          <el-table-column width="100" prop="out_num" label="出库数量">
            <template #default="scope">
              <span>
                {{ scope.row.out_num }} {{ scope.row.drug.min_package_unit_name }}
                <template v-if="scope.row.out_sub_num">{{ scope.row.out_sub_num }} {{
                  scope.row.drug.min_unit_name }}</template>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="出库单价" width="100">
            <template #default="scope">
              <span>{{ scope.row.out_price }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="出库总价" width="100">
            <template #default="scope">
              <span>{{ scope.row.total_out_price }}元</span>
            </template>
          </el-table-column>
          <el-table-column property="toUser.name" label="出库对象" width="100" />
          <el-table-column label="出库类型" align="center" width="120">
            <template #default="scope">
              <el-tag type="danger" size="mini" v-if="scope.row.dict_out_type == '205130000'">{{
                scope.row.dict_out_type_name }}</el-tag>
              <el-tag type="success" size="mini" v-if="scope.row.dict_out_type == '205120000'">{{
                scope.row.dict_out_type_name }}</el-tag>
              <el-tag type="info" size="mini" v-if="scope.row.dict_out_type == '205110000'">{{
                scope.row.dict_out_type_name }}</el-tag>
              <el-tag type="warning" size="mini" v-if="scope.row.dict_out_type == '205140000'">{{
                scope.row.dict_out_type_name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="out_time" label="出库时间" width="200" />
          <el-table-column property="operator.name" label="出库人" width="100" />
          <el-table-column label="出库来源" align="center" width="100">
            <template #default="scope">
              <el-tag type="success" size="mini" v-if="scope.row.dict_out_source == '905110000'">{{
                scope.row.dict_out_source_name }}</el-tag>
              <el-tag type="warning" size="mini" v-else>{{ scope.row.dict_out_source_name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template #default="scope">
              <el-button type="text" size="small" @click="editOut(scope.row)">
                <span class="txfonts modify">修改</span>
              </el-button>
              <el-popconfirm title="是否确定撤销?" @confirm="deatilsClick(scope.row)" v-if="scope.row.is_return == 0">
                <template #reference>
                  <el-button type="text" size="small" style="color:red">
                    撤销
                  </el-button>
                </template>
              </el-popconfirm>
              <span v-else style="cursor: pointer;">撤销</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
            :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.totalSum" @size-change="handleSizeChange"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>

    </div>
    <div v-if="!RKMain">
      <div class="display_flex top">
        <div class="search-top title-top">
          出库记录/出库
        </div>
        <div class="item">
          <el-button @click="RKBackClick" type="info">
            返回
          </el-button>
        </div>
      </div>
      <div class="tables display_flex">
        <div class="tables-left">
          <el-form :inline="true" :model="formData" class="demo-form-inline form-two">
            <el-form-item>
              <el-select clearable @change="typeYP" placeholder="选择种类" v-model="formData.dict_type">
                <el-option key="" label="全部" value=""></el-option>
                <el-option v-for="item in dictList[160000000]" :key="item.id" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input class="w-208" @input="searchYP" @keydown.enter="searchYP" v-model="formData.searchs" clearable
                placeholder="名称/拼音">
                <template #suffix>
                  <div @click="searchYP" class="searchcss">
                    <i class="fa fa-search"></i>
                  </div>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <el-table class="sss" v-loading="dataListLoading" :data="tableDataYP" border max-height="40vh" size="small"
            style="width: 100%">
            <el-table-column width="150" prop="name" label="名称" />
            <el-table-column width="120" prop="medi_spec" label="规格" show-overflow-tooltip />
            <el-table-column label="单价" width="100">
              <template #default="scope">
                <span>{{ scope.row.sell_price }}元</span>
              </template>
            </el-table-column>
            <el-table-column width="150" prop="company" label="厂家" show-overflow-tooltip />
            <el-table-column fixed="right" label="" width="60">
              <template #default="scope">
                <div class="iconcliss" @click="addList(scope.row)">
                  <el-icon>
                    <arrow-right-bold />
                  </el-icon>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-box">
            <el-pagination v-model:currentPage="pageDataYP.currentPage" v-model:page-size="pageDataYP.pageSize"
              :background="true" :page-sizes="[10, 20, 50, 100]" layout="sizes, prev, pager, next"
              :total="pageDataYP.totalSum" @size-change="handleSizeChangeYP"
              @current-change="handleCurrentChangeYP"></el-pagination>
          </div>
        </div>
        <div class="tables-right">
          <el-table class="sss" v-loading="dataListLoading" :data="tableDataRight" stripe border max-height="40vh">
            <el-table-column width="180" prop="name" label="名称" fixed>
              <template #default="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column width="180" label="批号" fixed>
              <template #default="scope">
                <el-input v-model="scope.row.batch_no" />
              </template>
            </el-table-column> -->
            <el-table-column prop="medi_spec" label="规格" show-overflow-tooltip />
            <el-table-column width="180" label="*数量">
              <template #default="scope">
                <el-input-number v-model="scope.row.out_num" :min="1" />
              </template>
            </el-table-column>
            <el-table-column width="180" label="备注">
              <template #default="scope">
                <el-input v-model="scope.row.remark" />
              </template>
            </el-table-column>
            <el-table-column prop="company" label="厂家" show-overflow-tooltip />
            <el-table-column fixed="right" label="操作" width="60">
              <template #default="scope">
                <el-popconfirm title="是否确定删除?" @confirm="rigthdel(scope.row, scope.$index)">
                  <template #reference>
                    <el-button type="text" size="small">
                      <span class="txfonts del">删除</span>
                    </el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <div class="saveRKSet">
            <el-form :inline="true" :model="formDataRK" class="demo-form-inline">
              <el-form-item label="出库对象:">
                <el-select clearable placeholder="选择出库对象" v-model="formDataRK.out_touid">
                  <el-option v-for="item in userInfo" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="出库类型:">
                <el-select clearable placeholder="选择出库类型" v-model="formDataRK.dict_out_type">
                  <el-option v-for="item in dictList[205000000]" :key="item.id" :label="item.name"
                    :value="item.code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="出库时间：">
                <el-date-picker v-model="formDataRK.out_time" type="datetime" :clearable="false"
                  value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择建立日期">
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="操作人：">
                <el-input v-model="formDataRK.user" placeholder="请输操作人">
                </el-input>
              </el-form-item>
              <el-form-item label="供货单位：">
                <el-input v-model="formDataRK.user" placeholder="请输入供货单位">
                </el-input>
              </el-form-item> -->
            </el-form>
          </div>
          <el-button style="float: right;margin-right: 20px;" type="primary" @click="Confirmpuncture()">确定</el-button>
        </div>
      </div>
    </div>
    <CKTJ ref="CKTJRef"></CKTJ>
    <!-- 修改弹窗 -->
    <div>
      <BaseDialog title="修改" @handleShow="(val)=>{stockOutDialog=val}" :isshow="stockOutDialog" width="700px"
        height="auto">
        <el-descriptions :column="3" size="small" border style="margin-top:10px;">
          <el-descriptions-item label="药品名称">{{ drug.name }}</el-descriptions-item>
          <el-descriptions-item label="规格">{{ drug.medi_spec }}</el-descriptions-item>
          <el-descriptions-item label="类别">{{ drug.fee_type }}</el-descriptions-item>
          <el-descriptions-item label="生产厂家">{{ drug.company }}</el-descriptions-item>
        </el-descriptions>
        <div class="addform" style="margin-top:10px;">
          <el-form :model="ruleFormOut" ref="ruleFormOutRef" label-width="120px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="批号">
                  <el-input disabled v-model="ruleFormOut.batch_no" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="出库数量">
                  <el-col :span="6">
                    换算系数 {{ drug.min_package_num }}
                  </el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormOut.out_num" placeholder="">
                      <template #append>{{ drug.min_package_unit_name }}</template>
                    </el-input>
                  </el-col>
                  <el-col :span="2"></el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormOut.out_sub_num" placeholder="">
                      <template #append>{{ drug.min_unit_name }}</template>
                    </el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="出库单位">
                  <el-input disabled v-model="drug.min_package_unit_name" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单价">
                  <el-input v-model="ruleFormOut.out_price" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="出库总价">
                  <template #default="scope">
                    <span v-if="drug.min_package_num">{{ (ruleFormOut.out_price * ruleFormOut.out_num +
                      ruleFormOut.out_price *
                      ruleFormOut.out_sub_num
                      / drug.min_package_num).toFixed(2) }}</span>
                    <span v-else>0.00</span>
                  </template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="出库对象:">
                  <el-select clearable placeholder="选择出库对象" v-model="ruleFormOut.out_touid">
                    <el-option v-for="item in userInfo" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="出库类型:">
                  <el-select clearable placeholder="选择出库类型" v-model="ruleFormOut.dict_out_type">
                    <el-option v-for="item in dictList[205000000]" :key="item.id" :label="item.name"
                      :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="出库时间：">
                  <el-date-picker v-model="ruleFormOut.out_time" type="datetime" :clearable="false"
                    value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择出库时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div style="text-align: right">
          <el-button @click="cancelOut">取消</el-button>
          <el-button type="primary" @click="handleOut()">确定</el-button>
        </div>
      </BaseDialog>
    </div>
    <printCK ref="printCKRef"></printCK>
  </div>
</template>

<script>
  import { Search, ArrowRightBold, ArrowLeftBold } from '@element-plus/icons-vue'
  import { toRefs, reactive, onMounted, nextTick, getCurrentInstance } from 'vue'
  import service from '@/utils/request'
  import BaseDialog from '@/components/Dialog/index.vue'
  import CKTJ from './CKJLTJ.vue'
  import { gitDictList, TimeYear, downLoadXlsx, TimestampToTime } from '@/utils/tool'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import printCK from './printCK'
  export default {
    components: {
      CKTJ,
      ArrowRightBold,
      BaseDialog,
      printCK
    },
    setup() {
      let { proxy } = getCurrentInstance()
      const state = reactive({
        formInline: {
          drug_name: '',
          time: ''
        },
        loading: true,
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        pageDataYP: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        tableData: [],
        CKTJRef: null,
        tags: [],
        formData: {
          time: '',
        },
        tableDataYP: [],
        listop: [],
        lists: [],
        doclist: [],
        doclists: [],
        RKMain: true,
        dictList: [],
        formDataRK: {},//出库保存
        tableDataRight: [],
        userInfo: [],
        ruleFormOut: {},
        stockOutDialog: false,
        drug: {}
      })
      onMounted(() => {
        initData()
        getUserList()
        tobody()
      })
      const getUserList = async () => {
        let res = await service.post('/api/user/get_all_nurse')
        if (res.code === 0) {
          state.userInfo = res.data.list
        }
      }
      // 获取字典
      const initData = async () => {
        state.dictList = await gitDictList([
          '332900000',
          '210000000',
          '103000000',
          '160000000',
          '116000000',
          '176000000',
          '231000000',
          '157000000',
          '158000000',
          '159000000',
          '339600000',
          '164000000',
          '113000000',
          '161000000',
          '100000000',
          '340200000',
          '339600000',
          '205000000'
        ])
        state.dictList[332900000].forEach((item) => {
          item.active = false
        })
        state.listop = state.dictList[332900000]
        state.lists = JSON.parse(JSON.stringify(state.dictList[332900000]))
        // 治疗项
        state.dictList[340200000].forEach((item) => {
          item.active = false
        })
        state.doclist = state.dictList[340200000]
        state.doclists = JSON.parse(JSON.stringify(state.dictList[340200000]))
      }
      // 获取列表
      const tobody = async () => {
        state.loading = true
        let data = {}
        if (state.formInline.time) {
          data = {
            size: state.pageData.pageSize,
            current: state.pageData.currentPage,
            start_time: state.formInline.time[0],
            end_time: state.formInline.time[1],
            drug_name: state.formInline.drug_name,
            dict_catalog_type: '160000000',
            batch_no: state.formInline.batch_no
          }
        } else {
          data = {
            size: state.pageData.pageSize,
            current: state.pageData.currentPage,
            drug_name: state.formInline.drug_name,
            dict_catalog_type: '160000000',
            batch_no: state.formInline.batch_no
          }
        }
        let res = await service.post('/api/store_out_record/get_out_record_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableData = res.data.records
            state.pageData.totalSum = res.data.total
          } else {
            state.tableData = []
            state.pageData.totalSum = 0
          }
        }
        state.loading = false
      }
      //退库
      const deatilsClick = async (row) => {
        let data = {
          record_id: row.id,
          dict_catalog_type: '160000000'
        }
        let res = await service.post('/api/store_out_record/return_record', data)
        if (res.code == 0) {
          ElMessage({
            message: '撤销成功',
            type: 'success',
          })
          tobody()
        }
      }
      // 条数改变时
      const handleSizeChange = (e) => {
        state.pageData.pageSize = e
        tobody()
      }
      // 页数改变时
      const handleCurrentChange = (e) => {
        state.pageData.currentPage = e
        tobody()
      }
      // 查询
      const search = () => {
        tobody()
      }
      const timeChange = (val) => {
        tobody()
      }
      //入库列表获取详情标签选择
      const tagBut = (row) => {
        console.log(row)
        row.active = !row.active
        let tabList = []
        const item = state.listop
        for (let i = 0; i < item.length; i++) {
          if (item[i].active) {
            tabList.push(item[i].code)
          }
        }
        state.tags = tabList
        RKLBCGetInfo()
      }
      // 条数改变时
      const handleSizeChangeYP = (e) => {
        state.pageData.pageSize = e
        RKLBCGetInfo()
      }
      // 页数改变时
      const handleCurrentChangeYP = (e) => {
        state.pageData.currentPage = e
        RKLBCGetInfo()
      }
      // 选择种类
      const typeYP = () => {
        // 入库列表点击之后获取药品信息
        RKLBCGetInfo()
      }
      // 搜索
      const searchYP = () => {
        // 入库列表点击之后获取药品信息
        RKLBCGetInfo()
      }
      // 获取列表
      const RKLBCGetInfo = async () => {
        state.dict_advice_type = '163110000'
        state.dict_catalog_type = '160000000'
        let data = {
          current: state.pageDataYP.currentPage,
          size: state.pageDataYP.pageSize,
          search_code: state.formData.searchs,
          is_enable: state.formData.status,
          dict_type: state.formData.dict_type,
          dict_advice_type: state.dict_advice_type,
          dict_catalog_type: state.dict_catalog_type,
          tag: state.tags,
        }
        let res = await service.post('/api/drug/list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableDataYP = res.data.records
            state.tableDataYP.forEach((item) => {
              item.drug_id = item.id
            })
            state.pageDataYP.totalSum = res.data.total
          } else {
            state.pageDataYP.totalSum = 0
            state.tableDataYP = []
          }
        }
      }
      //点击chu库
      const RKClick = () => {
        state.RKMain = false
        //获取当前登陆人信息
        let userData = JSON.parse(localStorage.getItem('ms_userData'))
        state.formDataRK.user = userData.name
        state.formDataRK.operator_id = userData.id
        let time = timestampToTime()
        state.formDataRK.out_time = time
        console.log(time)
        RKLBCGetInfo()
        getUserList()
      }
      const timestampToTime = () => {
        let date = new Date()
        let year = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return year + M + D + h + m + s;
      }
      //点击入库返回事件
      const RKBackClick = () => {
        state.RKMain = true
        state.pageData.currentPage = 1
        tobody()
      }
      const addList = (row) => {
        let param = {
          out_num: 1,
          remark: '',
          id: row.id,
          name: row.name,
          medi_spec: row.medi_spec,
          company: row.company,
        }
        console.log(param)
        if (state.tableDataRight.length == 0) {
          state.tableDataRight.push(param)
        } else {
          if (!(state.tableDataRight.some((item) => item.id == param.id))) {
            state.tableDataRight.push(param);
          } else {
            return ElMessage.error('不能添加重复的药品！')
          }
        }
      }
      // 右侧表格删除
      const rigthdel = async (row, index) => {
        console.log(row)
        row.out_num = undefined
        row.remark = ''
        state.tableDataRight.splice(index, 1)
      }
      const CKTJClick = () => {
        nextTick(() => {
          state.CKTJRef.tobodyAlert()
        })
      }
      // 导出
      const exportTable = (row) => {
        let data = {}
        if (state.formInline.time) {
          data = {
            start_time: state.formInline.time[0],
            end_time: state.formInline.time[1],
            drug_name: state.formInline.drug_name,
            dict_catalog_type: '160000000'
          }
        } else {
          data = {
            drug_name: state.formInline.drug_name,
            dict_catalog_type: '160000000'
          }
        }
        let time = TimestampToTime()
        let title = `科室药品出库${time}.xlsx`
        downLoadXlsx('/api/store_out_record/export_out_list', data, title)
      }
      // 修改出库
      const editOut = async (row) => {
        state.ruleFormOut = row
        state.drug = row.drug
        state.stockOutDialog = true
        console.log('state.stockOutDialog', state.stockOutDialog)
      }
      const handleOut = async (row) => {
        let url = '/api/store_out_record/edit_record'
        let data = {
          id: state.ruleFormOut.id == undefined ? '' : state.ruleFormOut.id,
          out_num: state.ruleFormOut.out_num == undefined ? '' : state.ruleFormOut.out_num,
          out_sub_num: state.ruleFormOut.out_sub_num == undefined ? '' : state.ruleFormOut.out_sub_num,
          out_touid: state.ruleFormOut.out_touid == null ? '' : state.ruleFormOut.out_touid,
          dict_out_type: state.ruleFormOut.dict_out_type == undefined ? '' : state.ruleFormOut.dict_out_type,
          out_time: state.ruleFormOut.out_time == undefined ? '' : state.ruleFormOut.out_time,
          out_price: state.ruleFormOut.out_price == undefined ? '' : state.ruleFormOut.out_price,
        }
        await service.post(url, data)
        state.stockOutDialog = false
        state.ruleFormOut = {}
        tobody()
      }
      const cancelOut = () => {
        state.stockOutDialog = false
        state.ruleFormOut = {}
      }
      // 新增确定 退货确定
      const Confirmpuncture = async (code) => {
        let data = {}
        let url = ''
        let arr = []
        //入库 保存功能  即保存左侧点击之后需要添加的右侧表格数据
        state.tableDataRight.forEach(item => {
          let obj = {
            drug_id: item.id == undefined ? '' : item.id,
            out_num: item.out_num == undefined ? '' : item.out_num,
            remark: item.remark == undefined ? '' : item.remark
          }
          arr.push(obj)
        })
        state.formDataRK.enter_drug = arr
        data = state.formDataRK
        data.dict_catalog_type = "160000000"
        url = '/api/store_out_record/create_record'
        if (state.formDataRK.enter_drug.some((val) => val.out_num == '')) {
          ElMessage({
            message: '数量不能为空',
            type: 'error',
          })
          return;
        }
        let res = await service.post(url, data)
        if (res.code == 0) {
          ElMessage({
            message: '出库成功',
            type: 'success',
          })
          state.formDataRK.enter_drug = []
          state.tableDataRight = []
          state.formDataRK.out_touid = ''
          state.formDataRK.dict_out_type = ''
        }
      }
      //修改批号
      const formSearchBatchNoChange = (val) => {
        state.formInline.batch_no = val
        state.pageData.currentPage = 1
        tobody()
      }
      const print = async () => {
        let data = {}
        if (state.formInline.time) {
          data = {
            current: 1,
            size: 10000,
            start_time: state.formInline.time[0],
            end_time: state.formInline.time[1],
            batch_no: state.formInline.batch_no,
            drug_name: state.formInline.drug_name,
            dict_catalog_type: "160000000"
          }
        } else {
          data = {
            current: 1,
            size: 10000,
            batch_no: state.formInline.batch_no,
            drug_name: state.formInline.drug_name,
            dict_catalog_type: "160000000"
          }
        }
        let res = await service.post('/api/store_out_record/get_out_record_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            let userData = JSON.parse(localStorage.getItem('ms_userData'))
            let data = {}
            if (state.formInline.time) {
              data = {
                time: state.formInline.time[0] + '~' + state.formInline.time[1],
                name: userData.name,
                drug_list: res.data.records
              }
            } else {
              data = {
                time: '',
                name: userData.name,
                drug_list: res.data.records
              }
            }
            proxy.$refs.printCKRef.initData(data)
            nextTick(() => {
              proxy.$refs.printCKRef.print()
            })
          } else {
            ElMessage.error('暂无打印数据')
          }
        }
      }
      return {
        ...toRefs(state),
        tobody,
        handleSizeChange,
        handleCurrentChange,
        search,
        CKTJClick,
        timeChange,
        RKLBCGetInfo,
        tagBut,
        RKClick,
        handleSizeChangeYP,
        handleCurrentChangeYP,
        timestampToTime,
        RKBackClick,
        addList,
        rigthdel,
        typeYP,
        searchYP,
        deatilsClick,
        getUserList,
        exportTable,
        Confirmpuncture,
        editOut,
        handleOut,
        cancelOut,
        formSearchBatchNoChange,
        print
      }
    },
  }
</script>

<style scoped lang="scss">
  .demo-form-inline{
    display: flex;
    flex-wrap: wrap;
  }
  .adviceBox {
    height: 100%;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 10px;
  }

  .demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }

  .display_flex {
    display: flex;
    justify-content: space-between;
  }

  .search-top .w-208 {
    width: 208px;

    .searchcss {
      cursor: pointer;
    }
  }

  .faicon {
    font-size: 19px;
    margin-right: 6px;
    cursor: pointer;
  }

  .title-top {
    align-items: center;
    display: flex;
  }

  .tabclis {
    height: 32px;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    display: flex;
    padding: 3px;

    .tabclis_right {
      display: flex;
      padding-top: 2px;
    }
  }

  .pagination-box {
    &:deep(.el-input__inner) {
      padding-right: 15px !important;
    }
  }

  .w-144 {
    margin-top: 20px;
  }

  .stop {
    background-color: #f4a939;
  }

  .modify {
    background-color: #3166ae;
  }

  .del {
    background-color: #ffecec;
    color: #ff6b6b;
    font-weight: 700;
  }

  .top {
    /* height: 35px; */
    margin: 8px 0;
  }
  .item{
    display: flex;
    flex-wrap: wrap;
  }
</style>

<style scoped>
  .saveRKSet {
    margin-top: 10px;
  }

  .el-input-group__append .el-select>>>.el-input__inner {
    border: none !important;
  }

  .el-form.w-144 .el-select {
    width: 100px !important;
  }

  .tables-left {
    margin-right: 10px;
    width: 30%;
  }

  .tables-right {
    margin-right: 10px;
    width: 70%;
  }

  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }

  .margin-set {
    padding: 10px;
    float: right;
  }

  .form-two {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .form-two .el-form-item {
    width: 48% !important;
  }

  .iconcliss {
    width: 32px;
    height: 32px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    color: #303030;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    font-size: 18px;
  }

</style>